<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Bonos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Bonos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-6">
                    <h5>Filtros</h5>
                  </div>
                  <div class="col-6">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-primary btn-sm"
                        @click="getIndex()"
                      >
                        <i class="fas fa-search"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_geston_bono_export"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.gestionBonos.export'
                          )
                        "
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        @click="limpiar()"
                      >
                        <i class="fas fa-broom"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pb-0">
                <div class="row">
                  <div class="form-group col-md-1">
                    <label>Id Bono</label>
                    <input
                      type="number"
                      v-model="filtros.id"
                      label="id"
                      class="form-control form-control-sm"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Guía</label>
                    <input
                      type="number"
                      v-model="filtros.guia_id"
                      class="form-control form-control-sm"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Vehículo</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.vehiculo_id"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Remolque</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.remolque_id"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Doc. Conductor</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      placeholder="Documento"
                      v-model="filtros.conductor_id"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Estado Viaje</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="filtros.estado_viaje"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Transportadora</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="transportadora"
                      label="razon_social"
                      :options="listasForms.empresas"
                      @input="seleccionarTransportadora()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Sitio Cargue</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="sitio_cargue"
                      label="nombre"
                      :options="listasForms.sitios"
                      @input="seleccionarSitioCargue()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Sitio Descargue</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="sitio_descargue"
                      label="nombre"
                      :options="listasForms.sitios"
                      @input="seleccionarSitioDescargue()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Ruta</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ruta"
                      label="nombre"
                      :options="listasForms.rutas"
                      @input="seleccionarRuta()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Estado Bono</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="filtros.estado"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option value="1">Generado</option>
                      <option value="2">Pagado</option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Fecha Pago</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_pago"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <div class="form-group">
                      <label for="fecha_expedicion">Fecha Expedición</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_expedicion"
                        @input="getIndex()"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-header border-0">
                <div class="col-md-12">
                  <div class="btn-group float-right">
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-toggle="modal"
                      data-target="#modal-recalcular-bonos"
                      v-if="
                        $store.getters.can('hidrocarburos.gestionBonos.create')
                      "
                    >
                      Recalcular Bonos
                    </button>

                    <button
                      type="button"
                      class="btn btn-success"
                      data-toggle="modal"
                      data-target="#modal-export-masivo"
                      @click="abrirModalMasivo()"
                    >
                      Adjuntar masivo
                    </button>
                  </div>
                  <br />
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <div class="row">
                  <div class="col-md-12">
                    <table
                      class="table table-bordered table-striped table-hover table-sm"
                      style="font-size: 0.8em"
                    >
                      
                      <thead class="thead">
                        <tr>
                          <th rowspan="2"></th>
                          <th rowspan="2">#</th>
                          <th rowspan="2">Guía / Ticket</th>
                          <th rowspan="2">Tipo Bono</th>
                          <th rowspan="2">Expedición</th>
                          <th rowspan="2">Fecha Pago</th>
                          <th rowspan="2">Vehículo</th>
                          <th rowspan="2">Remolque</th>
                          <th rowspan="2">Conductor</th>
                          <th rowspan="2">Transportadora</th>
                          <th rowspan="2">Ruta</th>
                          <th rowspan="2">Sitio Cargue</th>
                          <th rowspan="2">Sitio Descargue</th>
                          <th rowspan="2">Estado Viaje</th>
                          <th rowspan="2">Estado Bono</th>
                          <th
                            colspan="2"
                            class="text-center"
                            style="width: 20px;"
                          >
                            Montos
                          </th>
                        </tr>
                        <tr>
                          <th class="text-center">Entregado</th>
                          <th class="text-center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in bonos.data" :key="item.id">
                          <td class="pl-2">
                            <div class="btn-group float-right">
                              <!-- <a
                                class="btn btn-sm btn-primary"
                                style="cursor: pointer"
                                :href="uri_docs + item.link_soporte"
                                target="_blank"
                                v-if="
                                  $store.getters.can(
                                    'hidrocarburos.gestionBonos.printActa'
                                  ) && item.link_soporte
                                "
                              >
                                <i class="fas fa-print"></i>
                              </a> -->
                              <a
                                class="btn btn-sm btn-primary"
                                data-toggle="modal"
                                data-target="#modal-escaner-acta"
                                style="cursor: pointer"
                                @click="modalEscanerActa(item.viaje)"
                                v-if="
                                  $store.getters.can(
                                    'hidrocarburos.gestionBonos.printActa'
                                  )
                                "
                              >
                                <i class="fas fa-file-pdf"></i>
                              </a>
                              <!-- <a
                                class="btn btn-sm btn-primary"
                                style="cursor: pointer"
                                @click="printActa(item.viaje.id)"
                                v-if="
                                  $store.getters.can(
                                    'hidrocarburos.gestionBonos.printActa'
                                  ) && !item.link_soporte
                                "
                              >
                                <i class="fas fa-print"></i>
                              </a> -->
                              <button
                                class="btn btn-sm btn-warning"
                                v-if="
                                  $store.getters.can(
                                    'hidrocarburos.gestionBonos.printBono'
                                  )
                                "
                                @click="printBono(item.viaje.id)"
                              >
                                <i class="fas fa-print"></i>
                              </button>
                              <button
                                class="btn btn-sm bg-navy"
                                data-toggle="modal"
                                data-target="#Modal_docs"
                                v-if="
                                  $store.getters.can(
                                    'hidrocarburos.gestionBonos.edit'
                                  )
                                "
                                @click="abrirModalDoc('Editar', item)"
                              >
                                <i class="fas fa-edit"></i>
                              </button>
                            </div>
                          </td>
                          <td>{{ item.id }}</td>
                          <td>
                            <div
                              v-if="
                                item.viaje.guia !== null &&
                                  item.viaje.guia !== undefined
                              "
                            >
                              {{ item.viaje.guia.numero }}-{{
                                item.viaje.guia.digito_verificacion
                              }}
                            </div>
                            <div
                              v-if="
                                item.viaje.ticket !== null &&
                                  item.viaje.ticket !== undefined
                              "
                            >
                              {{ item.viaje.ticket.numero_ticket }}
                            </div>
                          </td>
                          <td>
                            {{
                              item.tipo_bono && item.tipo_bono.nombre
                                ? item.tipo_bono.nombre
                                : item.nTipoBono
                            }}
                          </td>
                          <td>
                            <div
                              v-if="
                                item.viaje.guia !== null &&
                                  item.viaje.guia !== undefined
                              "
                            >
                              {{
                                item.viaje.guia.det_guias[0].fecha_expedicion
                              }}
                            </div>
                            <div
                              v-if="
                                item.viaje.ticket !== null &&
                                  item.viaje.ticket !== undefined
                              "
                            >
                              {{ item.viaje.ticket.fecha_cargue }}
                            </div>
                          </td>
                          <td
                            :class="
                              item.link_soporte ? 'text-left' : 'text-center'
                            "
                          >
                            {{ item.fecha_pago }} <br />
                            <a
                              :href="uri_docs + item.link_soporte"
                              v-if="item.link_soporte"
                              target="_blank"
                            >
                              <i class="fas fa-download text-success"></i>
                            </a>
                            <i
                              class="fas fa-times-circle text-danger"
                              v-else
                            ></i>
                          </td>
                          <td>{{ item.viaje.vehiculo.placa }}</td>
                          <td>
                            {{
                              item.viaje.remolque
                                ? item.viaje.remolque.placa
                                : ""
                            }}
                          </td>
                          <td>
                            {{ item.viaje.conductor.nombres }}
                            {{ item.viaje.conductor.apellidos }}
                            <span class="badge badge-info">
                              {{ item.viaje.conductor.numero_documento }}
                            </span>
                          </td>
                          <td>{{ item.empresa.razon_social }}</td>
                          <td v-if="item.viaje.ruta != null">
                            {{ item.viaje.ruta.nombre }}
                          </td>
                          <td v-else></td>
                          <td v-if="item.viaje.sitio_cargue != null">
                            {{ item.viaje.sitio_cargue.nombre }}
                          </td>
                          <td v-else></td>
                          <td v-if="item.viaje.sitio_descargue != null">
                            {{ item.viaje.sitio_descargue.nombre }}
                          </td>
                          <td v-else></td>
                          <td class="text-center">
                            <span
                              class="badge"
                              :class="
                                item.viaje.estado == 1 || item.viaje.estado == 7
                                  ? 'badge-primary'
                                  : item.viaje.estado == 6 ||
                                    item.viaje.estado == 9
                                  ? 'bg-navy'
                                  : item.viaje.estado == 5 ||
                                    item.viaje.estado == 10
                                  ? 'badge-info'
                                  : item.viaje.estado == 4 ||
                                    item.viaje.estado == 2
                                  ? 'badge-success'
                                  : item.viaje.estado == 3
                                  ? 'badge-danger'
                                  : 'badge-dark'
                              "
                            >
                              {{ item.viaje.nEstado }}
                            </span>
                          </td>
                          <td>
                            <span
                              class="badge"
                              :class="
                                item.estado == 1
                                  ? 'badge-info'
                                  : item.estado == 2
                                  ? 'badge-success'
                                  : ''
                              "
                              >{{
                                item.estado == 1
                                  ? "Generado"
                                  : item.estado == 2
                                  ? "Pagado"
                                  : ""
                              }}</span
                            >
                          </td>
                          <td v-if="item.viaje.almacen_bonos">
                            <div
                              class="text-center"
                              v-for="almacen_bono in item.viaje.almacen_bonos"
                              :key="almacen_bono.id"
                            >
                              $
                              {{
                                parseInt(almacen_bono.monto).toLocaleString()
                              }}
                            </div>
                          </td>
                          <td v-else></td>
                          <td>
                            {{
                              item.programacion
                                ? "$" + item.programacion.monto.toLocaleString()
                                : ""
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="bonos.total">
                  <p>
                    Mostrando del <b>{{ bonos.from }}</b> al
                    <b>{{ bonos.to }}</b> de un total:
                    <b>{{ bonos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  class="float-right"
                  :data="bonos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="modal fade" id="Modal_docs">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Datos de Pago</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="bono">
            <div class="card-body bg-gray">
              <div class="row">
                <div class="col-md-4"><label>Bono Id</label></div>
                <div class="col-md-8">
                  {{ bono.id }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-4"><label>Guía / Ticket</label></div>
                <div
                  class="col-md-8"
                  v-if="
                    bono.viaje.guia !== null && bono.viaje.guia !== undefined
                  "
                >
                  {{ bono.viaje.guia.numero }}-{{
                    bono.viaje.guia.digito_verificacion
                  }}
                </div>
                <div
                  class="col-md-8"
                  v-if="
                    bono.viaje.ticket !== null &&
                      bono.viaje.ticket !== undefined
                  "
                >
                  {{ bono.viaje.ticket.numero_ticket }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-4"><label>Vehículo</label></div>
                <div class="col-md-8">
                  {{ bono.viaje.vehiculo.placa }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-4"><label>Conductor</label></div>
                <div class="col-md-8">
                  {{ bono.viaje.conductor.numero_documento }},
                  {{ bono.viaje.conductor.nombres }}
                  {{ bono.viaje.conductor.apellidos }}
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label for="fecha_pago">Fecha Pago</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    id="fecha_pago"
                    placeholder="Fecha Emisión"
                    v-model="formDoc.fecha_pago"
                    :class="
                      $v.formDoc.fecha_pago.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12" v-if="!bono.link_soporte">
                <input
                  type="file"
                  class="form-control-file"
                  ref="file"
                  id="btnFile"
                  @change="getFile"
                  accept="application/msword,application/pdf,application/rtf,image/*"
                />
              </div>
              <div class="col-lg-12" v-else>
                <label>Archivo Soprte</label><br />
                <a
                  class="btn btn-success"
                  :href="uri_docs + bono.link_soporte"
                  target="_blank"
                  >Ver <i class="fas fa-eye"></i
                ></a>
                <button
                  class="btn btn-danger float-right"
                  @click="eliminarDoc(bono.id)"
                  v-if="$store.getters.can('hidrocarburos.gestionBonos.delete')"
                >
                  Borrar <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </div>
          <div
            class="modal-footer justify-content-between"
            v-if="
              $store.getters.can('hidrocarburos.gestionBonos.edit') &&
                !$v.formDoc.$invalid &&
                fileDoc
            "
          >
            <button type="button" class="btn btn-primary" @click="saveDoc()">
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div
      class="modal fade"
      id="modal-export-masivo"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Exportacion de bonos masivo</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <table
                    class="
                      table table-bordered table-striped table-hover table-sm
                    "
                  >
                    <thead>
                      <tr>
                        <td>Id Bono</td>
                        <td>N° Guia</td>
                        <td>Vehiculo</td>
                        <td>Conductor</td>
                        <td>Sitio cargue</td>
                        <td>Sitio descargue</td>
                        <td>Masivo</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in masivoBonos" :key="item.id">
                        <td>{{ item.bono_id }}</td>
                        <td>
                          {{ item.guia }}
                        </td>
                        <td>{{ item.vehiculo }}</td>
                        <td>
                          {{ item.conductor }}
                        </td>
                        <td>
                          {{ item.sitio_cargue }}
                        </td>
                        <td>
                          {{ item.sitio_descargue }}
                        </td>
                        <td align="center">
                          <input
                            type="checkbox"
                            :id="'check' + item.bono_id"
                            v-model="item.check"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="fecha_pago_masivo">Fecha Pago</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      id="fecha_pag_masivoo"
                      placeholder="Fecha Emisión"
                      v-model="formMasivo.fecha_pago"
                      :class="
                        $v.formMasivo.fecha_pago.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <input
                  type="file"
                  class="form-control-file"
                  ref="file"
                  id="btnFile"
                  @change="getFile"
                  accept="application/msword,application/pdf,application/rtf,image/*"
                />
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="saveMasivo()"
                v-show="
                  !$v.formMasivo.$invalid &&
                    fileDoc !== null &&
                    masivoBonos.length > 0
                "
              >
                Import masivo
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>

        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div
      class="modal fade"
      id="modal-recalcular-bonos"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Recalculo de bonos</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal2"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-md-4">
                  <div class="form-group">
                    <label for="fecha_inicio">Fecha Inicio</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="formRecBono.fecha_inicio"
                      @input="validarFecha()"
                    />
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <div class="form-group">
                    <label for="fecha_fin">Fecha Fin</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="formRecBono.fecha_fin"
                      @input="validarFecha()"
                    />
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <label>Tipo ruta</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="formRecBono.tipo_ruta"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="tipo in listasForms.tipo_rutas"
                      :key="tipo.numeracion"
                      :value="tipo.numeracion"
                    >
                      {{ tipo.descripcion }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label>Sitio</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    v-model="sitio"
                    label="nombre"
                    :options="listasForms.sitios"
                    @input="seleccionarSitio()"
                  ></v-select>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="recalcularBonos()"
                v-show="!$v.formRecBono.$invalid"
              >
                Recalcular
                <i class="fa-solid fa-rotate-right"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <GestionBonoExport ref="GestionBonoExport" />
    <VentanaOperacionesEscanerActa ref="VentanaOperacionesEscanerActa" />
  </div>
</template>

<script>
import pagination from "laravel-vue-pagination";
import { required, minLength } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import axios from "axios";
import Loading from "../../../../components/Loading";
import GestionBonoExport from "./GestionBonoExport.vue";
import VentanaOperacionesEscanerActa from "./../../basico/ventanasOperaciones/VentanaOperacionesEscanerActa";
export default {
  name: "BonoIndex",
  components: {
    Loading,
    pagination,
    vSelect,
    GestionBonoExport,
    VentanaOperacionesEscanerActa,
  },
  data() {
    return {
      page: 1,
      cargando: false,
      bonos: {},
      bonos2: [],
      filtros: {},
      bono: null,
      transportadora: null,
      ruta: null,
      sitio: null,
      sitio_cargue: null,
      sitio_descargue: null,
      masivoBonos: [],
      bonoGuardar: [],
      formBono: { bono_id: null, check: null, cont: null },
      formMasivo: { fecha_pago: null },
      listasForms: {
        estados: [],
        sitios: [],
        rutas: [],
        empresas: [],
        tipo_rutas: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      formDoc: {},
      formRecBono: { sitio_id: null },
      fileDoc: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {
    formDoc: {
      fecha_pago: {
        required,
      },
    },
    formMasivo: {
      fecha_pago: { required },
    },
    formRecBono: {
      fecha_inicio: { required },
      fecha_fin: { required },
      tipo_ruta: { required },
      sitio_id: { required },
    },
  },
  methods: {
    getIndex(page = 1) {
      this.page = page;
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/bonoGuia?page=" + page, {
          params: this.filtros,
        })
        .then(async (response) => {
          this.cargando = false;
          this.bonos = response.data;
          await this.crearFormMasivo();
        });
    },

    limpiar() {
      this.filtros.id = null;
      this.filtros.guia_id = null;
      this.filtros.vehiculo_id = null;
      this.filtros.remolque_id = null;
      this.filtros.conductor_id = null;
      this.filtros.estado_viaje = null;
      this.filtros.transportadora_id = null;
      this.filtros.sitio_cargue_id = null;
      this.filtros.sitio_descargue_id = null;
      this.filtros.ruta_id = null;
      this.filtros.estado = null;
      this.filtros.fecha_pago = null;
      this.filtros.fecha_expedicion = null;

      // this.filtros.id = "";
      // this.filtros.guia_id = "";
      // this.filtros.ticket_id = "";
      // this.filtros.vehiculo_id = "";
      // this.filtros.remolque_id = "";
      // this.filtros.conductor_id = "";
      // this.filtros.producto_id = "";
      // this.filtros.transportadora_id = "";
      // this.filtros.sitio_cargue_id = "";
      // this.filtros.sitio_descargue_id = "";
      // this.filtros.ruta_id = "";
      // this.filtros.estado = "";
      // this.filtros.estado_viaje = "";
      // this.filtros.sitio_type = "";
      // this.filtros.fecha_inicio = "";
      // this.filtros.fecha_fin = "";
      // this.filtros.fecha_expedicion = "";
      this.transportadora = null;
      this.ruta = null;
      this.sitio_cargue = null;
      this.sitio_descargue = null;
      this.getIndex();
    },

    buscarRutas() {
      let me = this;
      var url = "api/admin/rutas/lista";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.rutas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarTransportadora() {
      let me = this;
      var url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarSitios() {
      let me = this;
      var url = "api/admin/sitios/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    validarFecha() {
      if (this.formRecBono.fecha_inicio && this.formRecBono.fecha_fin) {
        const fecha_ini = new Date(this.formRecBono.fecha_inicio);
        const fecha_fin = new Date(this.formRecBono.fecha_fin);
        if (fecha_ini > fecha_fin) {
          this.formRecBono.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicial no puede ser mayor a la Fecha Fin...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    seleccionarTransportadora() {
      if (this.transportadora != null) {
        this.filtros.transportadora_id = this.transportadora.id;
      } else {
        this.filtros.transportadora_id = null;
      }
      this.getIndex();
    },

    seleccionarRuta() {
      if (this.ruta != null) {
        this.filtros.ruta_id = this.ruta.id;
      } else {
        this.filtros.ruta_id = null;
      }
      this.getIndex();
    },

    seleccionarSitio() {
      if (this.sitio != null) {
        this.formRecBono.sitio_id = this.sitio.id;
      } else {
        this.formRecBono.sitio_id = null;
      }
    },

    seleccionarSitioCargue() {
      if (this.sitio_cargue != null) {
        this.filtros.sitio_cargue_id = this.sitio_cargue.id;
      } else {
        this.filtros.sitio_cargue_id = null;
      }
      this.getIndex();
    },

    seleccionarSitioDescargue() {
      if (this.sitio_descargue != null) {
        this.filtros.sitio_descargue_id = this.sitio_descargue.id;
      } else {
        this.filtros.sitio_descargue_id = null;
      }
      this.getIndex();
    },

    getEstados() {
      axios.get("/api/lista/39").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTipoRuta() {
      axios.get("/api/lista/48").then((response) => {
        this.listasForms.tipo_rutas = response.data;
      });
    },

    printBono(viaje_id) {
      this.cargando = true;
      axios
        .get(`/api/hidrocarburos/bonoGuia/printBono`, {
          params: { viaje_id },
        })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    getFile(e) {
      this.fileDoc = null;
      if (e.target.files[0]) {
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.fileDoc = file;
        } else {
          this.fileDoc = null;
        }
      }
    },

    abrirModalDoc(accion, bono) {
      this.modal.accion = accion;
      this.formDoc = {
        id: bono.id,
        fecha_pago: bono.fecha_pago,
      };
      this.bono = bono;
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        let inputImage = document.getElementById("btnFile");
        inputImage.value = "";
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    saveDoc() {
      if (!this.$v.formDoc.$invalid) {
        this.cargando = true;
        let formData = new FormData();
        formData.append("id", this.formDoc.id);
        formData.append("fecha_pago", this.formDoc.fecha_pago);
        formData.append("file", this.fileDoc);

        axios
          .post("/api/hidrocarburos/bonoGuia/pago", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.msg) {
              this.$swal({
                icon: "error",
                title: res.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            } else {
              this.getIndex(this.page);
              this.$refs.closeModal.click();
              this.formDoc = {};
              this.fileDoc = null;
              document.getElementById("btnFile").value = "";
              this.$swal({
                icon: "success",
                title: "Se guardo exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            }
            this.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
      } else {
        this.$swal({
          icon: "error",
          title: "Ocurrio un error, vuelva a intentarlo...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    async crearFormMasivo() {
      this.bonos2 = this.bonos.data;
      let cont = 0;
      this.masivoBonos = [];
      this.bonos2.forEach((element) => {
        let numero = null;
        if (element.viaje.guia !== null) {
          numero =
            element.viaje.guia.numero +
            "-" +
            element.viaje.guia.digito_verificacion;
        }

        if (element.viaje.ticket !== null) {
          numero = element.viaje.ticket.numero_ticket;
        }
        cont++;
        let form = {
          bono_id: element.id,
          check: 0,
          cont: cont,
          guia: numero,
          vehiculo: element.viaje.vehiculo.placa,
          conductor:
            element.viaje.conductor.nombres +
            " " +
            element.viaje.conductor.apellidos,
          sitio_cargue: element.viaje.sitio_cargue.nombre,
          sitio_descargue: element.viaje.sitio_descargue.nombre,
        };

        if (element.estado === 1) {
          this.masivoBonos.push(form);
        }
      });
    },

    abrirModalMasivo() {
      this.fileDoc = null;
      this.formMasivo.fecha_pago = null;
    },

    saveMasivo() {
      if (
        !this.$v.formMasivo.$invalid &&
        this.file !== null &&
        this.masivoBonos.length > 0
      ) {
        let m = this;
        // this.cargando = true;
        m.masivoBonos.forEach((element) => {
          if (element.check && element.check == 1) {
            m.bonoGuardar.push(element);
          }
        });

        let formData = new FormData();
        let maximo = 0;

        if (m.bonoGuardar.length > 0) {
          formData.append("minimo", m.bonoGuardar[0].bono_id);
          m.bonoGuardar.forEach((element) => {
            formData.append("bono-" + element.bono_id, element.bono_id);
            if (maximo < element.bono_id) {
              maximo = element.bono_id;
            }
          });
          formData.append("maximo", maximo);
          formData.append("fecha_pago", m.formMasivo.fecha_pago);
          formData.append("file", m.fileDoc);

          axios
            .post("/api/hidrocarburos/bonoGuia/pagoMasivo", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              if (res.data.msg) {
                this.$swal({
                  icon: "error",
                  title: res.data.msg,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 4000,
                  timerProgressBar: true,
                });
              } else {
                this.getIndex(this.page);
                this.$refs.closeModal.click();
                this.formDoc = {};
                this.fileDoc = null;
                document.getElementById("btnFile").value = "";
                this.$swal({
                  icon: "success",
                  title: "Se guardo exitosamente...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 4000,
                  timerProgressBar: true,
                });
              }
              this.cargando = false;
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        } else {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title:
              "No se ha seleccionado ningun bono, por favor seleccione al menos uno.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      } else {
        this.$swal({
          icon: "error",
          title: "Ocurrio un error, vuelva a intentarlo...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    recalcularBonos() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/bonoGuia/recaulcularBonos",
        data: this.formRecBono,
      })
        .then((response) => {
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: response.data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        });
    },

    eliminarDoc(idBono) {
      this.$swal({
        title: "Esta seguro de eliminar esta Documento?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          let datos = {
            id: idBono,
          };
          axios({
            method: "DELETE",
            url: "/api/hidrocarburos/bonoGuia/eliminarSoportePago",
            data: datos,
          }).then(() => {
            this.getIndex(this.page);
            this.bono.link_soporte = null;
            this.$swal({
              icon: "success",
              title: "Se elimino la Documento exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    modalEscanerActa(viaje) {
      this.$refs.VentanaOperacionesEscanerActa.llenar_modal_escaner_acta(viaje);
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getTipoRuta();
    this.buscarTransportadora();
    this.buscarRutas();
    this.buscarSitios();
  },
};
</script>
